import React, { useState, useEffect } from 'react';
import FormButtonRow from '../objects/FormButtonRow';
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import "flatpickr/dist/themes/material_green.css";
import './AgendaForm.css';
import SearchComponent from '../objects/SearchComponent';
import { useAuth } from '../auth/AuthContext';
import { useModal } from '../objects/ModalProvider';


const ToggleSwitch = ({ isOn, handleToggle }) => (
    <label className="switch">
        <input type="checkbox" checked={isOn} onChange={handleToggle} />
        <span className="slider"></span>
    </label>
);

const AgendaForm = ({ onSave, onCancel, submitLabel, agendaId, agenda: initialAgenda }) => {
    const [agenda, setAgenda] = useState({
        id: '',
        idCliente: '',
        nomeCliente: '',
        dataHoraInicio: new Date(),
        dataHoraFim: new Date(new Date().getTime() + 3600000),
        confirmado: false,
        cancelado: false,
        observacao: ''
    });
    const { userId, token } = useAuth();
    const urlBusca = `cliente/buscacliente?idusu=${userId}`
    const { showModal } = useModal();

    useEffect(() => {
        if (agendaId) {
            const loadUserData = async () => {
                try {
                    const response = await fetch(`agenda/agenda?idExterno=${agendaId}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, }});
                    const data = await response.json();
                    if (response.ok) {
                        setAgenda({
                            id: data.horarios[0].id,
                            idCliente: data.cliente.id,
                            nomeCliente: data.cliente.nome,
                            dataHoraInicio: new Date(data.horarios[0].dataHoraInicio),
                            dataHoraFim: new Date(data.horarios[0].dataHoraFim),
                            observacao: data.horarios[0].observacao,
                            confirmado: data.horarios[0].confirmado,
                            cancelado: data.horarios[0].cancelado
                        });
                    } else {
                        throw new Error('Failed to fetch user data');
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };
            loadUserData();
        }
    }, [agendaId, token]);

    useEffect(() => {
        setAgenda(prevAgenda => ({
            ...prevAgenda,
            ...initialAgenda
        }));
    }, [initialAgenda]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        const isCheckbox = event.target.type === 'checkbox';
        setAgenda(prevState => ({
            ...prevState,
            [name]: isCheckbox ? event.target.checked : value
        }));
    };

    const handleDateChange = (name, date) => {
        setAgenda(prevState => ({
            ...prevState,
            [name]: date[0]
        }));
        if (name === 'dataHoraInicio') {
            const newEndTime = new Date(date[0].getTime() + 3600000);
            setAgenda(prevState => ({
                ...prevState,
                dataHoraFim: newEndTime
            }));
        }
    };

    const handleUserSelect = (user) => {
        setAgenda(prevState => ({
            ...prevState,
            idCliente: user.id,
            nomeCliente: user.nome
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const method = agenda.id ? 'PUT' : 'POST';
        const url = agenda.id ? `agenda/atualizar/` : 'agenda/incluir';
        const adjustedData = {
            ...agenda,
            id: agenda.id || null,
            idCliente: agenda.idCliente || null,
            dataHoraInicio: adjustDate(agenda.dataHoraInicio),
            dataHoraFim: adjustDate(agenda.dataHoraFim)
        };

        try {
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(adjustedData)
            });
            if (response.ok) {
                onSave();
            } else {
                const data = await response.json();
                const errorMessages = Object.entries(data.errors || {})
                    .map(([key, value]) => `${key}: ${value.join(', ')}`)
                    .join('\n');
                showModal('Erro ao salvar agenda.', errorMessages, 'error');
            }
        } catch (error) {
            console.error("Failed to post user data:", error);
            showModal('Erro ao salvar agenda.', 'Erro ao salvar agenda - confira os dados.', 'error');
        }
    };

    const adjustDate = (date) => {
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() - userTimezoneOffset).toISOString();
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <input
                    type="hidden"
                    name="id"
                    value={agenda.id}
                    onChange={handleChange}
                    className="form-control"
                    required
                />
            </div>
            <div className="form-group">
                <label>Usuário:</label>
                <SearchComponent
                    searchUrl={urlBusca}
                    placeholder="Buscar cliente..."
                    mapResults={(item) => ({
                        id: item.id,
                        line1: item.nome,
                        line2: item.telefone
                    })}
                    onUserSelect={handleUserSelect}
                    value={agenda.nomeCliente}
                />
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label>Data e Hora de Início:</label>
                        <Flatpickr
                            data-enable-time
                            value={agenda.dataHoraInicio}
                            onChange={date => handleDateChange('dataHoraInicio', date)}
                            options={{
                                dateFormat: "d/m/Y H:i",
                                locale: Portuguese,
                                altInput: true,
                                altFormat: "d/m/Y H:i"
                            }}
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="form-group">
                        <label>Data e Hora de Fim:</label>
                        <Flatpickr
                            data-enable-time
                            value={agenda.dataHoraFim}
                            onChange={date => handleDateChange('dataHoraFim', date)}
                            options={{
                                dateFormat: "d/m/Y H:i",
                                locale: Portuguese,
                                altInput: true,
                                altFormat: "d/m/Y H:i"
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col, form-group">
                    <label>Confirmado:</label>
                    <ToggleSwitch
                        isOn={agenda.confirmado}
                        handleToggle={() => setAgenda(prevAgenda => ({ ...prevAgenda, confirmado: !prevAgenda.confirmado }))}
                    />
                </div>
                <div className="col, form-group">
                    <label>Cancelado:</label>
                    <ToggleSwitch
                        isOn={agenda.cancelado}
                        handleToggle={() => setAgenda(prevAgenda => ({ ...prevAgenda, cancelado: !prevAgenda.cancelado }))}
                    />
                </div>
            </div>
            <div className="form-group">
                <label>Observação:</label>
                <textarea
                    name="observacao"
                    value={agenda.observacao}
                    onChange={handleChange}
                    className="form-control"
                    rows="2"
                />
            </div>
            <FormButtonRow onSubmit={handleSubmit} onCancel={onCancel} submitLabel={submitLabel} />
        </form>
    );
};

export default AgendaForm;
