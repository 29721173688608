import React from 'react';
import './HomeFooter.css';

const HomeFooter = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-logo-section">
                    <img src="https://topgpt.azureedge.net/site/logo_menu_linha.png" alt="TopGPT Logo" className="footer-logo" />
                </div>
                <div className="footer-column">
                    <div className="footer-icon-text">
                        <i className="fas fa-map-marker-alt"></i>
                        <div>
                            <p>Rua José de Almeida Mathias, 194 - Cocais</p>
                            <p>35.975-000 - Barão de Cocais / Minas Gerais</p>
                        </div>
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-icon-text">
                        <i className="fab fa-whatsapp"></i>
                        <div>
                            <p>(31) 99731-7084</p>
                            <p>suporte@topgpt.com.br</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>TOPGPT Tecnologia LTDA - CNPJ: 57.006.993/0001-26</p>
            </div>
        </footer>
    );
};

export default HomeFooter;
