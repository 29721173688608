import React from 'react';
import { useAuth } from './AuthContext';

const ProtectedLink = ({ children, ...props }) => {
    const { userId } = useAuth();

    // Clona os elementos filhos e adiciona as props adicionais a eles
    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { ...props });
        }
        return child;
    });

    return userId ? childrenWithProps : null;
};

export default ProtectedLink;
