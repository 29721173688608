import React, { useEffect, useState } from 'react';
import './AssistenteForm.css';
import { useAuth } from '../auth/AuthContext';

const AssistenteForm = ({ idUsuario }) => {
    const [sections, setSections] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const { token } = useAuth();

    useEffect(() => {
        const fetchAssistentes = async () => {
            try {
                const response = await fetch(`./usuario/buscaassistentes?idexterno=${idUsuario}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                const data = await response.json();

                const assistentes = data || []; 
                const formattedSections = assistentes.map(assistente => ({
                    title: assistente.nome,
                    description: assistente.descricao,
                    imageUrl: assistente.urlImagem,
                    id: assistente.id,
                    idStatus: assistente.idStatus,
                    idUsuarioAssistente: assistente.idUsuarioAssistente,
                    valor: assistente.valor,
                }));
                setSections(formattedSections);
            } catch (error) {
                console.error('Error fetching assistentes:', error);
            }
        };

        const fetchStatusOptions = async () => {
            try {
                const response = await fetch(`./usuario/buscastatusassistentes`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                const data = await response.json();
                setStatusOptions(data || []);
            } catch (error) {
                console.error('Error fetching status options:', error);
            }
        };

        fetchAssistentes();
        fetchStatusOptions();
    }, [idUsuario, token]);

    const handleStatusChange = async (event, section) => {
        const newStatusId = event.target.value;
        try {
            let url;
            if (section.idStatus === null || section.idStatus === 0 || section.idStatus === '') {
                // Quando o valor da comboBox � "Selecione" e muda para qualquer outro valor
                url = `./usuario/usuarioassistente?idassistente=${section.id}&idusuario=${idUsuario}&idstatus=${newStatusId}`;
            } else {
                // Quando o valor da comboBox n�o � "Selecione"
                url = `./usuario/usuarioassistente?idusuarioassistente=${section.idUsuarioAssistente}&idstatus=${newStatusId}`;
            }
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, 
                },
            });

            if (response.ok) {
                const updatedSections = sections.map(assist =>
                    assist.id === section.id ? { ...assist, idStatus: newStatusId } : assist
                );
                setSections(updatedSections);
            } else {
                console.error('Error updating assistente status:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    return (
        <div className="page-container">
            <div className="sections-container">
                {sections.map((section, index) => (
                    <div className="card-assit" key={index}>
                        <img src={section.imageUrl} alt={section.title} className="card-assit-image" />
                        <div className="card-assit-content">
                            <select
                                value={section.idStatus || ''}
                                onChange={(e) => handleStatusChange(e, section)}
                                className="status-select"
                            >
                                <option value="" disabled style={{ color: 'gray', fontStyle: 'italic' }}>
                                    selecione
                                </option>
                                {statusOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.descricao}
                                    </option>
                                ))}
                            </select>
                            <div>
                                <h2>{section.title}</h2>
                                <p>{section.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AssistenteForm;
