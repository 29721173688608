import React, { useEffect, useState } from 'react';
import './AssistenteForm.css';
import { useAuth } from '../../auth/AuthContext';
import { useModal } from '../../objects/ModalProvider';

const getButtonProps = (status) => {
    switch (status) {
        case 'Ativo':
            return { textBtn: 'Cancelar', btnAtivo: true, action: 'delete' };
        case 'Cancelado':
            return { textBtn: 'Assinar', btnAtivo: true, action: 'put' };
        case 'Suspenso':
            return { textBtn: 'Verifique', btnAtivo: true, action: 'redirect' };
        case 'Assinado':
            return { textBtn: 'Aguarde', btnAtivo: false, action: null };
        default:
            return { textBtn: 'Assinar', btnAtivo: true, action: 'put' };
    }
};

const AssistenteForm = () => {
    const [sections, setSections] = useState([]);
    const { userId, token} = useAuth();
    const { showModal } = useModal();

    useEffect(() => {
        const fetchAssistentes = async () => {
            try {
                const response = await fetch(`./usuario/buscaassistentes?idusuario=${userId}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                const data = await response.json();

                const assistentes = data || [];
                const formattedSections = assistentes.map(assistente => {
                    const { textBtn, btnAtivo, action } = getButtonProps(assistente.status);
                    return {
                        title: assistente.nome,
                        description: assistente.descricao,
                        imageUrl: assistente.urlImagem,
                        textBtn,
                        btnAtivo,
                        action,
                        id: assistente.id,
                        idUsuarioAssistente: assistente.idUsuarioAssistente,
                        valor: assistente.valor,
                        isUpdated: false,
                    };
                });
                setSections(formattedSections);
            } catch (error) {
                console.error('Error fetching assistentes:', error);
            }
        };

        fetchAssistentes();
    }, [userId, token]);

    const handleButtonClick = async (idUsuAssist, idAssist, idUsu, action) => {
        try {
            if (action === 'redirect') {
                window.location.href = '/PagamentosForm';
                return;
            }

            let method = action === 'delete' ? 'DELETE' : 'PUT';
            let url = `./usuario/usuarioassistente?idassistente=${idAssist}&idusuario=${idUsu}` + (idUsuAssist ? `&idusuarioassistente=${idUsuAssist}` : '');

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, 
                },
            });

            if (response.ok) {
                // Agora, fazemos um novo GET para recuperar os dados atualizados
                const fetchUpdatedSections = async () => {
                    const response = await fetch(`./usuario/buscaassistentes?idusuario=${userId}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                    const data = await response.json();
                    return data || [];
                };

                const updatedSections = await fetchUpdatedSections();

                setSections(updatedSections.map(assistente => {
                    const { textBtn, btnAtivo, action } = getButtonProps(assistente.status);
                    return {
                        title: assistente.nome,
                        description: assistente.descricao,
                        imageUrl: assistente.urlImagem,
                        textBtn,
                        btnAtivo,
                        action,
                        id: assistente.id,
                        idUsuarioAssistente: assistente.idUsuarioAssistente,
                        valor: assistente.valor,
                        isUpdated: true, // Aplicar anima��o
                    };
                }));

                // Exibir a mensagem de sucesso
                showModal('Sucesso', 'Cadastro atualizado com sucesso!', 'success', null, 2000);

                // Remover a anima��o ap�s 2 segundos
                setTimeout(() => {
                    setSections(prevSections =>
                        prevSections.map(section => ({
                            ...section,
                            isUpdated: false,
                        }))
                    );
                }, 2000);
            } else {
                console.error('Error updating assistente:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    return (
        <div className="page-container">
            <h4>Assistentes</h4>
            <div className="sections-container">
                {sections.map((section, index) => (
                    <div className={`card-assit ${section.isUpdated ? 'updated' : ''}`} key={index}>
                        <img src={section.imageUrl} alt={section.title} className="card-assit-image" />
                        <div className="card-assit-content">
                            <button
                                disabled={!section.btnAtivo}
                                className="btn btn-primary"
                                onClick={() => handleButtonClick(section.idUsuarioAssistente, section.id, userId, section.action)}
                            >
                                {section.textBtn}
                            </button>
                            <div>
                                <h2>{section.title}</h2>
                                <p>{section.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AssistenteForm;
