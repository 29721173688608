import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';

const TopDescricao = ({ sections, classNamePrefix }) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={`${classNamePrefix}-description-container`}>
            {sections.map((section, index) => (
                <React.Fragment key={index}>
                    <div
                        className={`${classNamePrefix}-description-section`}
                        style={{
                            flexDirection: isMobile ? 'column' : (section.alinhaimagem === 'D' ? 'row-reverse' : 'row')
                        }}
                    >
                        <img
                            src={section.urlimagem}
                            alt={`Descrição ${index + 1}`}
                            className={`${classNamePrefix}-description-image`}
                        />
                        <div className={`${classNamePrefix}-description-content`}>
                            <div className={`${classNamePrefix}-description-title`}>
                                {section.titulo.split('\r\n').map((line, i) => (
                                    <React.Fragment key={i}>
                                        {line.split('**').map((part, j) => (
                                            <React.Fragment key={j}>
                                                {part}
                                                {j < line.split('**').length - 1 && <FaCheck />}
                                            </React.Fragment>
                                        ))}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                            <div className={`${classNamePrefix}-description-text`}>
                                <br />
                                {section.texto.split('\r\n').map((line, i) => (
                                    <React.Fragment key={i}>
                                        {line.split('**').map((part, j) => (
                                            <React.Fragment key={j}>
                                                {part}
                                                {j < line.split('**').length - 1 && <FaCheck />}
                                            </React.Fragment>
                                        ))}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                    {index < sections.length - 1 && (
                        <div
                            className={`${classNamePrefix}-transition-decorator`}
                            style={{
                                justifyContent: section.alinhaimagem === 'D' ? 'flex-start' : 'flex-end'
                            }}
                        >
                            <div className={`${classNamePrefix}-ellipse round`}></div>
                            <div className={`${classNamePrefix}-ellipse stretched`}></div>
                        </div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default TopDescricao;
