import React, { useState, useEffect, useRef, useCallback } from 'react';
import './ControleAgenda.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ptBr from '@fullcalendar/core/locales/pt-br';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import { useMediaQuery } from '@mui/material';

function convertDateToISO(dateStr) {
    const [month, day, yearTime] = dateStr.split('/');
    const [year, time] = yearTime.split(' ');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${time}`;
}

function getCurrentTimePosition() {
    const now = new Date();
    now.setMinutes(now.getMinutes() - 30);
    return now.toTimeString().slice(0, 5);
}

const ControleAgenda = () => {
    const [events, setEvents] = useState([]);
    const [currentStart, setCurrentStart] = useState(null);
    const [numDays, setNumDays] = useState(30);
    const navigate = useNavigate();
    const { userId } = useAuth();
    const { token } = useAuth();
    const calendarRef = useRef(null);
    const isSmallScreen = useMediaQuery('(max-width: 768px)');

    const fetchEvents = useCallback(async () => {
        try {
            const response = await fetch(`agenda/usuarioagenda?DataHoraInicio=${currentStart}&qtdDias=${numDays}&idUsuario=${userId}`,
                { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
            const data = await response.json();

            const eventsWithChanges = data.flatMap(item =>
                item.horarios.map(horario => ({
                    id: horario.idExterno,
                    title: `${item.cliente.nome} - ${horario.observacao ?? ''}`,
                    start: convertDateToISO(horario.dataHoraInicio),
                    end: convertDateToISO(horario.dataHoraFim),
                    color: horario.cancelado ? 'darkred' : horario.realizado ? 'darkcyan' : horario.confirmado ? 'darkgreen' : 'gray',
                    className: 'fade-in-event',
                    extendedProps: {
                        cancelado: horario.cancelado,
                    },
                }))
            );

            setEvents(eventsWithChanges);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    }, [currentStart, numDays, userId, token]);

    useEffect(() => {
        fetchEvents();
        const interval = setInterval(fetchEvents, 30 * 1000);
        return () => clearInterval(interval);
    }, [fetchEvents]);

    const handleDatesSet = (info) => {
        setCurrentStart(info.startStr);
        switch (info.view.type) {
            case 'timeGridWeek':
                setNumDays(7);
                break;
            case 'timeGridDay':
                setNumDays(1);
                break;
            default:
                setNumDays(30);
        }
    };

    const handleEventClick = ({ event }) => {
        navigate(`/Agenda/${event.id}`);
    };

    const handleDateSelect = (selectInfo) => {
        const title = 'novoEvento';
        const calendarApi = selectInfo.view.calendar;
        calendarApi.unselect();

        const newEvent = {
            title,
            start: selectInfo.startStr,
            end: selectInfo.endStr,
            allDay: selectInfo.allDay
        };
        setEvents([...events, newEvent]);
        navigate(`/Agenda?start=${selectInfo.startStr}&end=${selectInfo.endStr}`);
    };

    const handleEventDrop = async (info) => {
        const { id, start, end } = info.event;
        const adjustedStart = new Date(start.getTime() - start.getTimezoneOffset() * 60000).toISOString();
        const adjustedEnd = new Date(end.getTime() - end.getTimezoneOffset() * 60000).toISOString();

        try {
            const response = await fetch('./agenda/atualizarboard', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    idExterno: id,
                    dataHoraInicio: adjustedStart,
                    dataHoraFim: adjustedEnd,
                }),
            });

            if (!response.ok)
                throw new Error('Erro ao atualizar o evento');
            else
                setTimeout(async () => { await fetchEvents(); }, 50);

        } catch (error) {
            console.error('Error updating event:', error);
            info.revert();
        }
    };

    const handleNewEvent = () => {
        const now = new Date();
        const start = now.toISOString();
        now.setHours(now.getHours() + 1);
        const end = now.toISOString();
        handleDateSelect({
            startStr: start,
            endStr: end,
            allDay: false,
            view: {
                calendar: calendarRef.current.getApi()
            }
        });
    };

    const renderDayHeaderContent = (args) => {
        const dayOfWeek = args.date.toLocaleDateString('pt-BR', { weekday: isSmallScreen ? 'short' : 'long' });
        const dayOfMonth = args.date.toLocaleDateString('pt-BR', { day: '2-digit' });

        return (
            <div style={{ textAlign: 'left' }}>
                <div style={{ fontSize: '32px' }}>{dayOfMonth}</div>
                <div>{dayOfWeek.split("-")[0]}</div>
            </div>
        );
    };

    return (
        <FullCalendar
            ref={calendarRef}
            eventContent={(eventInfo) => {
                const fullName = eventInfo.event.title.split(' - ')[0];
                const names = fullName.split(' ');
                const userName = `${names[0]} ${names[names.length - 1]}`;
                const observacao = eventInfo.event.title.split(' - ')[1];

                return (
                    <div>
                        <div style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                            {userName}
                        </div>
                        <div>
                            {observacao}
                        </div>
                    </div>
                );
            }}
            locale={ptBr}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'today newButton timeGridWeek,timeGridDay'
            }}
            customButtons={{
                newButton: {
                    text: 'Novo',
                    click: handleNewEvent
                }
            }}
            initialView='timeGridWeek'
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            events={events}
            datesSet={handleDatesSet}
            slotMinTime="00:00:00"
            slotMaxTime="24:00:00"
            allDaySlot={false}
            eventDisplay="block"
            eventClick={handleEventClick}
            select={handleDateSelect}
            eventDrop={handleEventDrop}
            height="100%"
            firstDay={0}
            dayHeaderContent={renderDayHeaderContent}
            scrollTime={getCurrentTimePosition()}
            eventAllow={(dropInfo, draggedEvent) => {
                return !draggedEvent.extendedProps.cancelado;
            }}
            titleFormat={{ month: 'long' }}
            dayHeaderFormat={isSmallScreen ? { weekday: 'short' } : { weekday: 'long' }}
        />
    );
};

export default ControleAgenda;
