import React from 'react';
import './HomeIntelligence.css';

const HomeIntelligence = () => {
    return (
        <div className="intelligence-section">
            <div className="row">
                <span>QUEM SOMOS</span>
                <h1>topGPT</h1>
                <h2><b>Inteligência Artificial Sob Medida para Seu Negócio</b></h2>
                <p>
                    A inteligência artificial (IA) deixou de ser uma tecnologia do futuro e já faz parte da realidade de milhares de empresas. Com a capacidade de aprender, analisar e automatizar, os produtos de IA estão revolucionando a maneira como trabalhamos e interagimos com o mundo.
                </p>
                <p>
                    Desde soluções simples, como chatbots, até sistemas avançados de análise de dados, a IA oferece uma gama diversificada de produtos que podem ser adaptados para diferentes necessidades. A seguir, destacamos alguns dos principais tipos de produtos de IA que estão moldando o mercado.
                </p>
            </div>
            <div className="inteli-cards-container">
                <div className="inteli-card inteli-dark-card">
                    <h3>Automação Inteligente:</h3>
                    <p>Economize tempo e recursos com automação de processos rotineiros e repetitivos.</p>
                </div>
                <div className="inteli-card inteli-orange-card">
                    <h3>Automação Inteligente:</h3>
                    <p>Economize tempo e recursos com automação de processos rotineiros e repetitivos.</p>
                </div>
                <div className="inteli-card inteli-dark-card">
                    <h3>Automação Inteligente:</h3>
                    <p>Economize tempo e recursos com automação de processos rotineiros e repetitivos.</p>
                </div>
            </div>
        </div>
    );
};

export default HomeIntelligence;
