import React, { useState } from 'react';
import { useAuth } from '../auth/AuthContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './Login.css';
import { useModal } from '../objects/ModalProvider';

const Login = () => {
    const { login, anonToken } = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';
    const { showModal } = useModal();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = 'login/auth';
        const adjustedData = { username, password };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${anonToken}`,
                },
                body: JSON.stringify(adjustedData)
            });
            if (response.ok) {
                const data = await response.json();
                login(data.id, data.nome, data.token);
                navigate(from);  
            } else {
                const errorText = await response.text();
                showModal('Erro ao atualizar senha:', 'Falha no login: ' + errorText, 'error');

            }
        } catch (error) {
            showModal('Erro na requisição:', 'Erro na requisição: ' + error.message, 'error');
        }
    };

    return (
        <div className="login-container">
            <div className="login-form-container">
                <form onSubmit={handleSubmit} className="login-form">
                    <h2>Bem vindo !</h2>
                    <div className="form-group">
                        <label htmlFor="username">Usuário</label>
                        <input
                            id="username"
                            type="text"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            placeholder="Informe seu usuário"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Senha</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            placeholder="Informe sua senha"
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Entrar</button>
                    <Link to="/recuperasenha" className="forgot-password">Esqueceu a senha?</Link>
                </form>
            </div>
            <div className="image-side"></div>
        </div>
    );
};

export default Login;
