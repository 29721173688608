import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { useAuth } from '../auth/AuthContext';

const Layout = ({ children }) => {
    const { anonToken, setAnonToken, browserInfo, ipInfo } = useAuth();
    const [loading, setLoading] = useState(true);
    const [isFetchingAnonToken, setIsFetchingAnonToken] = useState(false);

    useEffect(() => {
        const fetchAnonToken = async () => {
            if (
                !anonToken &&
                browserInfo &&
                Object.keys(browserInfo).length > 0 &&
                ipInfo &&
                Object.keys(ipInfo).length > 0 &&
                !isFetchingAnonToken
            ) {
                setIsFetchingAnonToken(true);

                const userLogin = {
                    username: 'jota_anonimo',
                    password: 'jota_test3',
                    browserInfo: browserInfo,
                    ipInfo: ipInfo
                };

                try {
                    const response = await fetch('login/auth-anom', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(userLogin)
                    });

                    if (!response.ok) {
                        throw new Error('Falha na autentica��o an�nima');
                    }

                    const data = await response.json();
                    setAnonToken(data.token);
                } catch (error) {
                    console.error('Erro ao buscar token an�nimo:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchAnonToken();
    }, [anonToken, browserInfo, ipInfo, setAnonToken, isFetchingAnonToken]);

    if (loading) {
        return <div>Carregando...</div>;
    }

    return (
        <div>
            <NavMenu />
            <Container style={{ paddingTop: '25px' }}>
                {children}
            </Container>
        </div>
    );
};

export default Layout;
