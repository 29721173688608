import React from 'react';

const FormButtonRow = ({ onSubmit, onCancel, submitLabel, disabled }) => {
    return (
        <div className="row" align="center">
            <div className="col">
                <button type="submit" className="btn btn-primary" disabled={disabled} onClick={onSubmit}>{submitLabel}</button>
            </div>
        </div>
    );
};

export default FormButtonRow ;
