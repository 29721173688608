import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NewUserForm from './NewUserForm';
import FormContainer from '../objects/FormContainer';
import { useModal } from '../objects/ModalProvider';
import './NewUser.css';

const NewUser = () => {
    const navigate = useNavigate();
    const { assist } = useParams();
    const { showModal } = useModal();

    const handleCancel = () => navigate('/');

    return (
        <FormContainer>
            <div className="new-user-form-container">
                <div className="new-user-form">
                    <h3>Seja bem vindo !!!</h3>
                    <NewUserForm 
                        onSave={() => showModal('', 'Usuário salvo com sucesso!', 'success', '/', 2000)} 
                        onCancel={handleCancel} 
                        assist={assist} 
                        submitLabel="Confirmar" 
                    />
                </div>
            </div>
        </FormContainer>
    );
};

export default NewUser;
