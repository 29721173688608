import React, { Component } from 'react';
import './ControleAssistente.css';
export class ControleAssistente extends Component {
    static displayName = ControleAssistente.name;

    constructor(props) {
        super(props);
        this.state = { ctrlAssist: [], loading: true };
    }

    componentDidMount() {
        this.populaControleAssistente();
    }

    handleTokenChange = (id, value) => {
        const updatedCtrlAssist = this.state.ctrlAssist.map(item => {
            if (item.id === id) {
                return { ...item, tokenZap: value };
            }
            return item;
        });
        this.setState({ ctrlAssist: updatedCtrlAssist });
    };

    async populaControleAssistente() {
        const response = await fetch('tokenzap');
        const data = await response.json();
        this.setState({ ctrlAssist: data, loading: false });
    }

    handleSubmit = async (id, tokenZap) => {
        const payload = { id, tokenZap };

        const response = await fetch('tokenzap', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (response.ok) {
            alert("Token atualizado com sucesso!");
        } else {
            alert("Token NÃO atualizado !!!");
        }
    };

    renderCard(ctrlAssistItem) {
        return (
            <div className="card-ctrlassit" key={ctrlAssistItem.id}>
                <div className="card-ctrlassit-body">
                    <h5 className="card-title">{ctrlAssistItem.nome}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{ctrlAssistItem.telefone}</h6>
                    <div className="mb-2">
                        <div className="card-label">IdTelefone:</div>
                        <div className="card-result">{ctrlAssistItem.idTelefone}</div>
                    </div>
                    <div className="mb-2">
                        <div className="card-label">IdAssistente:</div>
                        <div className="card-result">{ctrlAssistItem.idAssistente}</div>
                    </div>
                    <div className="mb-2">
                        <div className="card-label">IdAssistenteFilho:</div>
                        <div className="card-result">{ctrlAssistItem.idAssistenteFilho}</div>
                    </div>
                    <div className="mb-2">
                        <div className="card-label">TokenZap:</div>
                        <input
                            type="text"
                            value={ctrlAssistItem.tokenZap}
                            onChange={(e) => this.handleTokenChange(ctrlAssistItem.id, e.target.value)}
                            className="form-control"
                            placeholder="TokenZap"
                        />
                    </div>
                    <button
                        className="btn btn-primary"
                        onClick={() => this.handleSubmit(ctrlAssistItem.id, ctrlAssistItem.tokenZap)}
                    >
                        Update Token
                    </button>
                </div>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Carregando...</em></p>
            : <div className="card-container">
                {this.state.ctrlAssist.map(item => this.renderCard(item))}
            </div>;

        return (
            <div>
                <h1 id="tabelLabel" >Controle de Assistentes</h1>
                <p>Informações sobre assistentes.</p>
                {contents}
            </div>
        );
    }
}
