import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import FormButtonRow from '../objects/FormButtonRow';
import InputMask from 'react-input-mask';
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import './ClienteForm.css';
import { useModal } from '../objects/ModalProvider';

const ClienteForm = ({ onCancel, assist, submitLabel }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { userId } = useAuth();
    const { token } = useAuth();
    const [mask, setMask] = useState("(99) 99999-9999");
    const [showResponsavelFields, setShowResponsavelFields] = useState(false);
    const [cliente, setCliente] = useState({
        idUsuario: userId,
        nome: '',
        telefone: '',
        email: '',
        cpf: '',
        nascimento: new Date(),
        responsavel: '',
        telefoneResponsavel: '',
        indicacao: '',
    });
    const { showModal } = useModal();

    useEffect(() => {
        if (id) {
            const fetchCliente = async () => {
                try {
                    const response = await fetch(`./cliente/unico?idExterno=${id}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setCliente({
                        ...data,
                        nascimento: data.nascimento ? new Date(data.nascimento) : '',
                        telefone: data.telefone.startsWith('55') ? data.telefone.substring(2) : data.telefone,
                        telefoneResponsavel: data.telefoneResponsavel === null ? '' : data.telefoneResponsavel.startsWith('55') ? data.telefoneResponsavel.substring(2) : data.telefoneResponsavel 
                    });
                    defineMascara(data.telefone);
                    setShowResponsavelFields(checkIfMinor(data.nascimento));
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                }
            };

            fetchCliente();
        }
    }, [id, token]);

    const checkIfMinor = (nascimento) => {
        const birthDate = new Date(nascimento);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        return age < 18;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setCliente(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'telefone' || name === 'telefoneResponsavel') {
            defineMascara(value);
        }

        if (name === 'nascimento') {
            setShowResponsavelFields(checkIfMinor(value));
        }
    };

    const defineMascara = (valor) => {
        let numericValue = valor.replace(/\D/g, '');

        if (numericValue.startsWith('55')) {
            numericValue = numericValue.substring(2);
        }

        if (numericValue[2] >= '6') {
            setMask("(99) 99999-9999");
        } else {
            setMask("(99) 9999-9999");
        }
    }

    const handleDateChange = (date) => {
        setCliente(prevState => ({
            ...prevState,
            nascimento: date[0]
        }));
        setShowResponsavelFields(checkIfMinor(date[0]));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const method = id ? 'PUT' : 'POST';
        const url = id ? `./cliente?idCliente=${id}` : './cliente';

        try {

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(cliente)
            });
            if (response.ok) {
                showModal('Sucesso', 'Cliente salvo com sucesso!', 'success', '/controlecliente', 2000);
            } else {
                const data = await response.json();
                const errorMessages = Object.entries(data.errors || {})
                    .map(([key, value]) => `${key}: ${value.join(', ')}`)
                    .join('\n');
                showModal('Erro ao salvar cliente:', errorMessages, 'error');
            }
        } catch (error) {
            showModal('Erro na requisição:', 'Erro técnico ao salvar cliente. ' + error.message, 'error');
        }
    };

    const handleCancel = () => {
        onCancel();
        navigate('/controlecliente', { state: { message: "Operação cancelada.", searchQuery: location.state?.searchQuery || '' } });
    };

    return (
        <form onSubmit={handleSubmit} className="cliente-form">
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label>Nome:</label>
                    <input
                        type="text"
                        name="nome"
                        value={cliente.nome}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Telefone:</label>
                    <InputMask
                        mask={mask}
                        maskChar={null}
                        value={cliente.telefone}
                        onChange={handleChange}
                        type="tel"
                        name="telefone"
                        className="form-control"
                        required
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={cliente.email}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>CPF:</label>
                    <InputMask
                        mask="999.999.999-99"
                        maskChar={null}
                        type="text"
                        name="cpf"
                        value={cliente.cpf}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label>Data de Nascimento:</label>
                    <Flatpickr
                        value={cliente.nascimento}
                        onChange={handleDateChange}
                        options={{
                            dateFormat: "d/m/Y",
                            locale: Portuguese,
                            altInput: true,
                            altFormat: "d/m/Y",
                            enableTime: false,
                            noCalendar: false,
                            time_24hr: true
                        }}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Indicação:</label>
                    <input
                        type="text"
                        name="indicacao"
                        value={cliente.indicacao}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            {showResponsavelFields && (
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label>Responsável:</label>
                        <input
                            type="text"
                            name="responsavel"
                            value={cliente.responsavel}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Telefone do Responsável:</label>
                        <InputMask
                            mask={mask}
                            maskChar={null}
                            value={cliente.telefoneResponsavel}
                            onChange={handleChange}
                            type="tel"
                            name="telefoneResponsavel"
                            className="form-control"
                        />
                    </div>
                </div>
            )}
            <FormButtonRow onSubmit={handleSubmit} onCancel={handleCancel} submitLabel={submitLabel} />
        </form>
    );
};

export default ClienteForm;
