import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SliderTG.css';
import { useAuth } from '../auth/AuthContext';

const SliderTG = () => {
    const navigate = useNavigate();
    const sliderRef = useRef(null);
    const [carouselSections, setCarouselSections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isWriting, setIsWriting] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const { anonToken } = useAuth();

    useEffect(() => {
        const fetchCarousel = async () => {
            try {
                const response = await fetch('./home/buscacarrossel', { method: 'GET', headers: { 'Authorization': `Bearer ${anonToken}`, } });
                const data = await response.json();
                const formattedCarousel = data.map(carrossel => ({
                    nome: carrossel.nome,
                    descricao: carrossel.descricao,
                    urlImagem: carrossel.urlImagem,
                    urlClick: carrossel.urlClick,
                    textoBotao: carrossel.textoBotao,
                    id: carrossel.id,
                    corFrame: carrossel.corFrame || "darkblue"
                }));
                setCarouselSections(formattedCarousel);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching carousel:', error);
            }
        };

        fetchCarousel();
    }, [anonToken]);

    useEffect(() => {
        if (carouselSections.length > 0) {
            const images = carouselSections.map(section => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.src = section.urlImagem;
                    img.onload = resolve;
                    img.onerror = resolve; // Mesmo em caso de erro, considere a imagem carregada
                });
            });

            Promise.all(images).then(() => {
                setImagesLoaded(true);
                setTimeout(() => {
                    setIsAnimating(true);
                    setTimeout(() => {
                        setIsWriting(true);
                    }, 1000);
                }, 1000);
            });
        }
    }, [carouselSections]);

    const changeSlide = () => {
        setIsWriting(false);
        setIsFadingOut(true);

        setTimeout(() => {
            if (sliderRef.current) {
                sliderRef.current.slickNext();
            }
            setIsFadingOut(false);
        }, 1000);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        appendDots: dots => (
            <div>
                <ul> {dots} </ul>
            </div>
        ),
        beforeChange: (oldIndex, newIndex) => {
            setIsAnimating(false);
            setCurrentSlide(newIndex);
        },
        afterChange: (current) => {
            setIsAnimating(true);
            setTimeout(() => {
                setIsWriting(true);
            }, 1000);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            changeSlide();
        }, 15000);

        return () => clearInterval(interval);
    }, [currentSlide]);

    const TypingText = ({ text }) => {
        const [typedText, setTypedText] = useState('');

        useEffect(() => {
            let currentText = '';
            let isErrorMade = false;
            let corrected = false;
            let errorLength = 0;
            let i = 0;

            const type = () => {
                if (i < text.length) {
                    if (!isErrorMade && Math.random() <= 0.005) {
                        const errorChars = Math.floor(Math.random() * 4) + 1;
                        const randomString = Array.from({ length: errorChars }, () => String.fromCharCode(97 + Math.floor(Math.random() * 26))).join('');
                        currentText += randomString;
                        isErrorMade = true;
                        errorLength = errorChars;
                    } else if (isErrorMade && !corrected) {
                        setTimeout(() => {
                            const deleteChar = () => {
                                if (errorLength > 0) {
                                    currentText = currentText.slice(0, -1);
                                    errorLength--;
                                    setTypedText(currentText);
                                    setTimeout(deleteChar, 140);
                                } else {
                                    corrected = true;
                                    setTimeout(type, 300);
                                }
                            };
                            deleteChar();
                        }, 250);
                        return;
                    } else {
                        currentText += text.charAt(i);
                        i++;
                        isErrorMade = false;
                        corrected = false;
                    }
                    setTypedText(currentText);
                    setTimeout(type, isErrorMade ? 80 : 40);
                }
            };
            type();
        }, [text]);

        return <span className="typewriter-cursor">{typedText}</span>;
    };

    return (
        <div className="slider-container">
            {isLoading || !imagesLoaded ? (
                <div className="skeleton-loader-home"></div>
            ) : (
                <Slider ref={sliderRef} {...settings} className="carousel-container">
                    {carouselSections.map((section, index) => (
                        <div className="carousel" key={index}>
                            <img src={section.urlImagem} alt={section.nome} className="carousel-image" />
                            <div className={`carousel-content ${isAnimating && currentSlide === index ? 'show' : ''} ${isFadingOut && currentSlide === index ? 'fade-out' : ''}`}
                                style={{ backgroundColor: section.corFrame }}
                            >
                                <div className="carousel-text-container">
                                    <div className="carousel-header-container">
                                        <h2>{section.nome}</h2>
                                    </div>
                                    <div className="typewriter-container">
                                        {isWriting && currentSlide === index && (
                                            <TypingText key={section.id} text={section.descricao} />
                                        )}
                                    </div>
                                </div>
                                <div className="slider-button-container">
                                    <button className="btn btn-primary slider-button" onClick={() => navigate(`${section.urlClick}`)}>
                                        {section.textoBotao}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
};

export default SliderTG;
