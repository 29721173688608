import React, { useState, useEffect, useCallback } from 'react';
import FormButtonRow from '../../objects/FormButtonRow';
import InputMask from 'react-input-mask';
import { useModal } from '../../objects/ModalProvider';
import { useAuth } from '../../auth/AuthContext';
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import "flatpickr/dist/themes/material_green.css";

const CadastroForm = ({ onSave, onCancel, submitLabel }) => {
    const [mask, setMask] = useState("(99) 99999-9999");
    const [usuario, setUsuario] = useState({
        Telefone: '',
        Nome: '',
        Email: '',
        Profissao: '',
        Nascimento: '',
    });
    const [originalUsuario, setOriginalUsuario] = useState({});
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const { showModal } = useModal();
    const { userId, token } = useAuth();

    const loadUserData = useCallback(async () => {
        try {
            const response = await fetch(`usuario/buscausuariologado?id=${userId}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
            const data = await response.json();
            if (response.ok) {
                const userData = {
                    Id: userId,
                    Telefone: data.telefone || '',
                    Nome: data.nome || '',
                    Email: data.email || '',
                    Profissao: data.profissao || '',
                    Nascimento: data.nascimento ? new Date(data.nascimento) : '',
                };
                setUsuario(userData);
                setOriginalUsuario(userData);
                setIsDataLoaded(true);
            } else {
                throw new Error('Failed to fetch user data');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
            showModal('Erro ao buscar dados do usuário.', 'Erro ao buscar dados do usuário - confira os dados.', 'error');
        }
    }, [userId, showModal, token]);

    useEffect(() => {
        if (!isDataLoaded) {
            loadUserData();
        }
    }, [isDataLoaded, loadUserData]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch('usuario', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(usuario)
            });
            if (response.ok) {
                showModal('Sucesso', 'Cadastro atualizado com sucesso!', 'success', null, 2000);
                onSave();
            } else {
                const text = await response.text();
                const data = JSON.parse(text);
                const errorMessages = Object.entries(data.errors || {})
                    .map(([key, value]) => `${key}: ${value.join(', ')}`)
                    .join('\n');
                showModal('Erro ao atualizar usuário:', errorMessages, 'error');
            }
        } catch (error) {
            console.error("Failed to update user data:", error);
            showModal('Erro técnico ao atualizar.', 'Tente novamente.', 'error');
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUsuario(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'Telefone') {
            const numericValue = value.replace(/\D/g, '');
            if (numericValue[2] >= 6) {
                setMask("(99) 99999-9999");
            } else {
                setMask("(99) 9999-9999");
            }
        }
    };

    const handleDateChange = (date) => {
        setUsuario(prevState => ({
            ...prevState,
            Nascimento: date[0]
        }));
    };

    const handleCancel = () => {
        showModal(
            'Confirmar cancelamento',
            'Você tem certeza que deseja cancelar? As alterações feitas serão perdidas.',
            'question',
            null,
            null,
            () => {
                setUsuario(originalUsuario);
                onCancel();
            }
        );
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="usuario-form">
                <div className="form-row">
                    <div className="form-group">
                        <label>Nome:</label>
                        <input
                            type="text"
                            name="Nome"
                            value={usuario.Nome}
                            onChange={handleChange}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label>Telefone:</label>
                        <InputMask
                            mask={mask}
                            maskChar={null}
                            value={usuario.Telefone}
                            onChange={handleChange}
                            type="tel"
                            name="Telefone"
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>E-mail:</label>
                        <input
                            type="email"
                            name="Email"
                            value={usuario.Email}
                            onChange={handleChange}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label>Profissão:</label>
                        <input
                            type="text"
                            name="Profissao"
                            value={usuario.Profissao}
                            onChange={handleChange}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="form-group col-md-6">
                        <label>Data de Nascimento:</label>
                        <Flatpickr
                            value={usuario.Nascimento}
                            onChange={handleDateChange}
                            options={{
                                dateFormat: "d/m/Y",
                                locale: Portuguese,
                                altInput: true,
                                altFormat: "d/m/Y",
                                enableTime: false,
                                noCalendar: false,
                                time_24hr: true
                            }}
                            className="form-control"
                            required
                        />
                    </div>
                </div>
                <br />
                <FormButtonRow onSubmit={handleSubmit} onCancel={handleCancel} submitLabel={submitLabel} />
            </form>
        </>
    );
};

export default CadastroForm;
