import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AgendaForm from './AgendaForm';
import FormContainer from '../objects/FormContainer';
import { useModal } from '../objects/ModalProvider';

function Agenda() {
    const { agendaId } = useParams();
    const { showModal } = useModal();
    const [agenda, setAgenda] = useState({
        dataHoraInicio: new Date(),
        dataHoraFim: new Date()
    });
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const start = queryParams.get('start');
    const end = queryParams.get('end');

    useEffect(() => {
        if (start && end) {
            setAgenda(prevState => ({
                ...prevState,
                dataHoraInicio: new Date(start),
                dataHoraFim: new Date(end)
            }));
        }
    }, [start, end]);

    const handleCancel = () => {
        showModal('Cancelamento', 'Alterações canceladas...', 'error', '/controleagenda', 2000);
    };

    const handleNextStep = (message) => {
        showModal('Agenda salva com sucesso!', 'Registro atualizado com sucesso!', 'success', '/controleagenda', 2000);
    };

    return (
        <FormContainer>
            <h4>Evento</h4>
            <AgendaForm onSave={handleNextStep} onCancel={handleCancel} submitLabel="Confirmar" agendaId={agendaId} agenda={agenda} />

        </FormContainer>
    );
}

export default Agenda;

