import React, { useEffect, useState } from 'react';
import './TopDigitador.css';
import { FaCheck } from 'react-icons/fa';
import TopSecDesc from '../objects/TopDescricao';
import TypingText from '../objects/TypingText';
import UsuarioForm from './NewUserForm';
import { useModal } from '../objects/ModalProvider';
import { useAuth } from '../auth/AuthContext';
import HomeFooter from '../objects/HomeFooter';

const TopDigitador = () => {
    const [sections, setSections] = useState([]);
    const [functionalities, setFunctionalities] = useState([]);
    const [startTyping, setStartTyping] = useState(false);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [showTextContainer, setShowTextContainer] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // Estado para abrir o modal com a imagem
    const { showModal } = useModal();
    const { anonToken } = useAuth();

    useEffect(() => {
        const timer = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);

        const fetchSections = async () => {
            try {
                const response = await fetch('institucional/topdigitadordesc', {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${anonToken}` },
                });
                const data = await response.json();
                if (Array.isArray(data)) {
                    const validSections = data.filter(
                        (section) => section && section.texto && section.urlimagem
                    );
                    setSections(validSections);
                } else {
                    console.error('Dados recebidos para descrições não são um array:', data);
                }
            } catch (error) {
                console.error('Erro ao buscar descrições:', error);
            }
        };

        const fetchFunctionalities = async () => {
            try {
                const response = await fetch('institucional/topdigitadorfunc', {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${anonToken}` },
                });
                const data = await response.json();
                if (Array.isArray(data)) {
                    setFunctionalities(data);
                } else {
                    console.error('Dados recebidos para funcionalidades não são um array:', data);
                }
            } catch (error) {
                console.error('Erro ao buscar funcionalidades:', error);
            }
        };

        fetchSections();
        fetchFunctionalities();

        return () => clearTimeout(timer);
    }, [anonToken]);

    useEffect(() => {
        if (isImageLoaded) {
            const timer = setTimeout(() => {
                setShowTextContainer(true);
                setStartTyping(true);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [isImageLoaded]);

    const handleImageLoad = () => {
        setIsImageLoaded(true);
    };

    const handleFormSave = () => {
        showModal('Usuário salvo com sucesso!', 'Cadastro realizado! Em instantes você receberá uma mensagem no WhatsApp.', 'success', '/', 5000);
    };

    const handleCardClick = (urlImagem) => {
        if (window.innerWidth <= 768) {
            setSelectedImage(urlImagem);
        }
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    return (
        <div className="topdigitador-page">
            <div className="topdigitador-header-section">
                {!isImageLoaded && <div className="skeleton-loader"></div>}
                <img
                    src="https://topgpt.azureedge.net/site/topdigitador.jpg"
                    alt="Background"
                    className="topdigitador-background-image"
                    onLoad={handleImageLoad}
                    style={{ display: isImageLoaded ? 'block' : 'none' }}
                />
                {showTextContainer && (
                    <div className="topdigitador-impact-text-container">
                        <div>
                            <h2>topDigitador</h2>
                        </div>
                        <div>
                            {startTyping && (
                                <TypingText text="Seu amigo que transcreve o seu aúdio com fidelidade, em qualquer momento, a qualquer hora..." />
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="topdigitador-description-container">
                <TopSecDesc sections={sections} classNamePrefix="topdigitador" />
            </div>

            <div className="topdigitador-functionalities">
                <h2><b>Funcionalidades</b></h2>
                <div className="functionalities-grid">
                    {functionalities.map((func, index) => (
                        <div
                            className="functionality-item"
                            key={index}
                            onClick={() => handleCardClick(func.urlimagem)}
                        >
                            <div className="functionality-card">
                                <div className="functionality-card-front">
                                    <div className="functionality-card-title">
                                        <h4><b>{func.titulo}</b></h4>
                                    </div>
                                    <div className="functionality-card-text">
                                        <p>
                                            {func.texto.split('\r\n').map((line, i) => (
                                                <React.Fragment key={i}>
                                                    {line.split('**').map((part, j) => (
                                                        <React.Fragment key={j}>
                                                            {part}
                                                            {j < line.split('**').length - 1 && <FaCheck />}
                                                        </React.Fragment>
                                                    ))}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="functionality-card-back"
                                    style={{
                                        backgroundImage: `url(${func.urlimagem})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                ></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="topdigitador-form-container">
                <UsuarioForm
                    onSave={handleFormSave}
                    assist="topDigitador"
                    submitLabel="Cadastrar"
                />
            </div>

            <HomeFooter />

            {selectedImage && (
                <div className="ts-modal-overlay" onClick={closeModal}>
                    <div className="ts-modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="ts-modal-close">
                            <button className="ts-modal-btn" onClick={closeModal}>fechar</button>
                        </div>
                        <img src={selectedImage} alt="Imagem da funcionalidade" className="ts-modal-image" />
                    </div>
                </div>
            )}

        </div>

    );
};

export default TopDigitador;
