import React from 'react';
import './HomeTrust.css';

const HomeTrust = () => {
    return (
        <div className="trust-section">
            <div className="row">
                <span>POR QUE NOS ESCOLHER ???</span>
                <h1>Por que confiar na <b>topGPT</b></h1>
                <p>
                    Com o avanço da tecnologia, muitas empresas estão buscando soluções de inteligência artificial para automatizar processos, aumentar a eficiência e melhorar a experiência do cliente.
                </p>
                <p>
                    A TopGPT se destaca como uma escolha confiável nesse mercado competitivo. Mas o que torna a TopGPT a melhor opção para o seu negócio? Aqui estão alguns motivos que fazem toda a diferença:
                </p>
            </div>
            <div className="trust-cards-container">
                <div className="trust-card trust-dark-card">
                    <h3>1.</h3>
                    <p>Soluções Personalizadas</p>
                </div>
                <div className="trust-card trust-orange-card">
                    <h3>2.</h3>
                    <p>Equipe de Especialistas em IA</p>
                </div>
                <div className="trust-card trust-dark-card">
                    <h3>3.</h3>
                    <p>Implementação Simples e Eficiente</p>
                </div>
                <div className="trust-card trust-orange-card">
                    <h3>4.</h3>
                    <p>Soluções Personalizadas</p>
                </div>
                <div className="trust-card trust-dark-card">
                    <h3>5.</h3>
                    <p>Implementação Simples e Eficiente</p>
                </div>
                <div className="trust-card trust-orange-card">
                    <h3>6.</h3>
                    <p>Equipe de Especialistas em IA</p>
                </div>

            </div>
        </div>
    );
};

export default HomeTrust;
