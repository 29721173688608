import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ClienteForm from './ClienteForm';
import ConversasList from './ConversasList';
import EnderecoList from './EnderecoList';
import EnderecoForm from './EnderecoForm';
import FormContainer from '../objects/FormContainer';
import { Tab, Nav } from 'react-bootstrap';
import './Cliente.css';
import { useModal } from '../objects/ModalProvider'; 

const Cliente = () => {
    const navigate = useNavigate();
    const { assist, id } = useParams();
    const { showModal } = useModal(); 
    const [editingEndereco, setEditingEndereco] = useState(null);
    const [activeKey, setActiveKey] = useState('cliente');
    const isNewClient = !id;

    useEffect(() => {
        console.log(`Cliente Component Mounted. assist: ${assist}, id: ${id}`);
    }, [assist, id]);

    const handleSave = (message) => {
        showModal('Sucesso', message, 'success', null, 2000); 
        if (editingEndereco) {
            setEditingEndereco(null);
        } else {
            navigate('/');
        }
    };

    const handleCancel = () => {
        if (editingEndereco) {
            setEditingEndereco(null);
        } else {
            navigate('/');
        }
    };

    return (
        <FormContainer>
            <div className="cli-content-child">
                <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                    <Nav variant="tabs" className="nav-tabs-custom">
                        <Nav.Item>
                            <Nav.Link eventKey="cliente">Cliente</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="endereco" disabled={isNewClient}>Endereço</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="conversas" disabled={isNewClient}>Conversas</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="cliente">
                            <ClienteForm
                                onSave={() => handleSave("Cliente salvo com sucesso!")}
                                onCancel={handleCancel}
                                assist={assist}
                                submitLabel="Salvar"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="endereco">
                            {editingEndereco ? (
                                <EnderecoForm
                                    endereco={editingEndereco}
                                    onSave={handleSave}
                                    onCancel={handleCancel}
                                    submitLabel="Salvar"
                                />
                            ) : (
                                <EnderecoList
                                    idCliente={id || assist}
                                    onEdit={setEditingEndereco}
                                    onNew={() => setEditingEndereco({ idClienteExterno: id || assist })}
                                />
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="conversas">
                            {id && activeKey === 'conversas' && (
                                <ConversasList
                                    idUsuario={null}
                                    idCliente={id}
                                />
                            )}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </FormContainer>
    );
};

export default Cliente;
