import React, { useState, useEffect } from 'react';
import './Login.css';
import { useModal } from '../objects/ModalProvider';
import { useAuth } from '../auth/AuthContext';

const RecuperaSenha = () => {
    const [email, setEmail] = useState('');
    const [recoveryCode, setRecoveryCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [step, setStep] = useState(1);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [timer, setTimer] = useState(5);
    const [idUsuario, setIdUsuario] = useState('');
    const { showModal } = useModal();
    const { anonToken } = useAuth();

    useEffect(() => {
        if (step === 2) {
            setIsButtonDisabled(true);
            const interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setIsButtonDisabled(false);
                        return 5;  // Reset timer for potential next use
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
    }, [step]);

    const handleRecovery = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('login/recuperasenha', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${anonToken}`, },
                body: JSON.stringify({ email })
            });
            const retData = await response.json(); 
            if (response.ok) {
                setIdUsuario(retData.idUsuario);
                setStep(2);
            }
            else {
                showModal('Erro na requisição:', retData.message || 'Falha ao enviar o email de recuperação.', 'error');

            }
        } catch (error) {
            showModal('Erro na requisição:', 'Erro na requisição: ' + error.message, 'error');

        }
    };

    const handleValidateRecoveryCode = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('login/validatoken', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${anonToken}`, },
                body: JSON.stringify({ ValidaToken: recoveryCode, Id: idUsuario })
            });
            if (response.ok) setStep(3);
            else showModal('Erro na requisição:', 'Código de recuperação inválido.', 'error');
        } catch (error) {
            showModal('Erro na requisição:', 'Erro na requisição: ' + error.message, 'error');
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            showModal('Erro na requisição:', 'As senhas não coincidem.', 'error');
            return;
        }
        try {
            const response = await fetch('login/alterasenha', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${anonToken}`, },
                body: JSON.stringify({ Id: idUsuario, ValidaToken: recoveryCode, Senha: newPassword })
            });
            if (response.ok) showModal('Senha atualizada', 'Senha atualizada com sucesso !', 'success', '/login', 2000);
            else showModal('Erro...', 'Falha ao alterar a senha.', 'error');
        } catch (error) {
            showModal('Erro...', 'Erro na requisição: ' + error.message, 'error')
        }
    };

    return (
        <div className="login-container">
            <div className="login-form-container">
                <form className="login-form">
                    {step === 1 && (
                        <div className="form-group">
                            <h5>Informe o e-mail cadastrado:</h5>
                            <p />
                            <div className="form-group">
                                <label htmlFor="email">E-mail</label>
                                <input
                                    id="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Informe seu e-mail"
                                    required
                                />
                                <p /><p />
                                <button type="button" className="login-button" onClick={handleRecovery}>Enviar código de recuperação</button>
                            </div>
                        </div>
                    )}
                    {step === 2 && (
                        <div className="form-group">
                            <h5>Informe o token recebido:</h5>
                            <p />
                            <div className="form-group">
                                <label htmlFor="recoveryCode">Código de recuperação</label>
                                <input
                                    id="recoveryCode"
                                    type="text"
                                    value={recoveryCode}
                                    onChange={(e) => setRecoveryCode(e.target.value)}
                                    placeholder="Código de recuperação"
                                    required
                                />
                                <p /><p />
                                <button
                                    type="button"
                                    className="login-button countdown-button"
                                    onClick={handleValidateRecoveryCode}
                                    disabled={isButtonDisabled}
                                >
                                    {isButtonDisabled ? `Confira seu email... ${timer}s` : 'Validar'}
                                </button>
                            </div>
                        </div>
                    )}
                    {step === 3 && (
                        <div className="form-group">
                            <h4>Informe a nova senha:</h4>
                            <p />
                            <div className="form-group">
                                <label htmlFor="newPassword">Nova senha</label>
                                <input
                                    id="newPassword"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="Nova senha"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirmar nova senha</label>
                                <input
                                    id="confirmPassword"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="Confirmar nova senha"
                                    required
                                />
                                <p /><p />
                                <button type="button" className="login-button" onClick={handleChangePassword}>Alterar senha</button>
                            </div>
                        </div>
                    )}
                </form>
            </div>
            <div className="image-side"></div>
        </div>
    );
};

export default RecuperaSenha;
