import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UsuarioForm from './UsuarioForm';
import ConversasList from './ConversasList';
import AssistenteForm from './AssistenteForm';
import FormContainer from '../objects/FormContainer';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Tab, Nav } from 'react-bootstrap';
import './Usuario.css';

const Usuario = () => {
    const navigate = useNavigate();
    const { assist, id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [editingEndereco, setEditingEndereco] = useState(null);
    const [activeKey, setActiveKey] = useState('usuario');
    const isNewClient = !id;

    useEffect(() => {
        console.log(`Usuario Component Mounted. assist: ${assist}, id: ${id}`);
    }, [assist, id]);

    const handleShowModal = (message) => {
        setModalMessage(message);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleSave = (message) => {
        handleShowModal(message);
        if (editingEndereco) {
            setEditingEndereco(null);
        } else {
            navigate('/');
        }
    };

    const handleCancel = () => {
        if (editingEndereco) {
            setEditingEndereco(null);
        } else {
            navigate('/');
        }
    };

    return (
        <FormContainer>
            <div className="usu-content-child ">
                <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
                    <Nav variant="tabs" className="nav-tabs-custom">
                        <Nav.Item>
                            <Nav.Link eventKey="usuario">Usuario</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="conversas" disabled={isNewClient}>Conversas</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="assistentes" disabled={isNewClient}>Assistentes</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="usuario">
                            <UsuarioForm
                                onSave={() => handleSave("Usuário salvo com sucesso!")}
                                onCancel={handleCancel}
                                assist={assist}
                                submitLabel="Salvar"
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey="conversas">
                            {id && activeKey === 'conversas' && (
                                <ConversasList
                                    idUsuario={id}
                                    idCliente={null}
                                />
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="assistentes">
                            {id && activeKey === 'assistentes' && (
                                <AssistenteForm
                                    idUsuario={id}
                                />
                            )}
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Mensagem</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalMessage}</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </FormContainer>
    );
};

export default Usuario;
