import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        userId: sessionStorage.getItem('userId'),
        token: sessionStorage.getItem('token'),
        username: sessionStorage.getItem('username'),
        anonToken: sessionStorage.getItem('anonToken'),
        browserInfo: JSON.parse(sessionStorage.getItem('browserInfo') || '{}'),
        ipInfo: JSON.parse(sessionStorage.getItem('ipInfo') || '{}'),
    });

    // Coletar informa��es do navegador
    useEffect(() => {
        const browserInfo = {
            appName: navigator.appName,
            appVersion: navigator.appVersion,
            platform: navigator.platform,
            userAgent: navigator.userAgent,
            language: navigator.language
        };
        setAuthState(prevState => ({
            ...prevState,
            browserInfo
        }));
    }, []);

    // Obter informa��es de IP e localiza��o
    useEffect(() => {
        fetch('https://ipapi.co/json/')
            .then(response => response.json())
            .then(data => {
                const ipInfo = {
                    ip: data.ip,
                    country: data.country_name,
                    region: data.region,
                    city: data.city,
                    timezone: data.timezone
                };
                setAuthState(prevState => ({
                    ...prevState,
                    ipInfo
                }));
            })
            .catch(error => {
                console.error('Erro ao buscar informa��es de IP:', error);
            });
    }, []);

    // Remover o fetch do token an�nimo daqui

    // Atualizar sessionStorage sempre que authState mudar
    useEffect(() => {
        const { userId, token, username, anonToken, browserInfo, ipInfo } = authState;
        if (userId) {
            sessionStorage.setItem('userId', userId);
            sessionStorage.setItem('token', token || '');
            sessionStorage.setItem('username', username || '');
        } else {
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('username');
        }
        if (anonToken) {
            sessionStorage.setItem('anonToken', anonToken);
        }
        if (browserInfo) {
            sessionStorage.setItem('browserInfo', JSON.stringify(browserInfo));
        }
        if (ipInfo) {
            sessionStorage.setItem('ipInfo', JSON.stringify(ipInfo));
        }
    }, [authState]);

    const login = (userId, username, token) => {
        setAuthState(prevState => ({
            ...prevState,
            userId,
            token,
            username
        }));
    };

    const logout = () => {
        setAuthState(prevState => ({
            ...prevState,
            userId: null,
            token: null,
            username: null
            // Observa��o: anonToken permanece
        }));
    };

    // Adicionar a fun��o setAnonToken
    const setAnonToken = (anonToken) => {
        setAuthState(prevState => ({
            ...prevState,
            anonToken
        }));
    };

    return (
        <AuthContext.Provider value={{ ...authState, login, logout, setAnonToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth deve ser usado dentro de um AuthProvider');
    }
    return context;
};
