import React, { useState, useEffect, useCallback, useRef } from 'react';
import './ConversasList.css';
import { useAuth } from '../auth/AuthContext';

const ConversasList = ({ idUsuario, idCliente }) => {
    const [conversas, setConversas] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [conversasPerPage] = useState(4);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [expandedGptRunIds, setExpandedGptRunIds] = useState({});
    const observer = useRef();
    const { token } = useAuth();

    const fetchConversas = useCallback(async (page) => {
        if (page > totalPages) return;
        setLoading(true);
        try {
            const response = await fetch(`/conversas?page=${page}&limit=${conversasPerPage}` + (idUsuario ? `&idUsuario=${idUsuario}` : '') + (idCliente ? `&idCliente=${idCliente}` : ''), { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erro na requisição');
            }
            const data = await response.json();
            setConversas(prevConversas => (page === 1 ? data.conversas : [...prevConversas, ...data.conversas]));
            setTotalPages(data.totalPages);
        } catch (err) {
            console.error(err.message);
        } finally {
            setLoading(false);
        }
    }, [idUsuario, conversasPerPage, totalPages, idCliente, token]);

    useEffect(() => {
        if (idUsuario || idCliente) {
            fetchConversas(currentPage);
        }
    }, [idUsuario, idCliente, currentPage, fetchConversas]);

    const lastCardElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && currentPage < totalPages) {
                setCurrentPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, currentPage, totalPages]);

    const isJson = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    };

    const formatJsonMessage = (message) => {
        try {
            const jsonMessage = JSON.parse(message);
            return (
                <div>
                    {Array.isArray(jsonMessage)
                        ? jsonMessage.map((item, index) => (
                            <div key={index}>
                                {Object.keys(item).map((key) => (
                                    item[key] !== null ? (
                                        <div key={key}>
                                            <strong>{key}:</strong> {typeof item[key] === 'object' ? formatJsonMessage(JSON.stringify(item[key])) : String(item[key])}
                                        </div>
                                    ) : null
                                ))}
                            </div>
                        ))
                        : Object.keys(jsonMessage).map((key) => (
                            jsonMessage[key] !== null ? (
                                <div key={key}>
                                    {key === 'Acao' ? <strong key={key}>{jsonMessage[key]}<br /></strong> : <span key={key}>{key}: {String(jsonMessage[key])}<br /></span>}
                                </div>
                            ) : null
                        ))
                    }
                </div>
            );
        } catch (e) {
            return <span>{message}</span>;
        }
    };

    const handleToggleExpand = (gptrunid) => {
        setExpandedGptRunIds(prev => ({ ...prev, [gptrunid]: !prev[gptrunid] }));
    };

    const groupedConversas = conversas.reduce((acc, conversa) => {
        if (conversa.origem === 'function') {
            if (!acc[conversa.gptRunId]) {
                acc[conversa.gptRunId] = [];
            }
            acc[conversa.gptRunId].push(conversa);
        } else {
            acc[conversa.id] = [conversa];
        }
        return acc;
    }, {});

    return (
        <div>
            <div className="card-conversa-container">
                {Object.keys(groupedConversas).length === 0 && !loading && <div className="no-records-message">Não existem registros.</div>}
                {Object.keys(groupedConversas).sort((a, b) => {
                    const conversaA = groupedConversas[a][0];
                    const conversaB = groupedConversas[b][0];
                    return conversaB.id - conversaA.id; // Ordenação decrescente
                }).map((key, index) => {
                    const conversasGroup = groupedConversas[key];
                    const isFunctionGroup = conversasGroup[0].origem === 'function';
                    return (
                        <div key={key} className="card-conversa" ref={index === Object.keys(groupedConversas).length - 1 ? lastCardElementRef : null}>
                            {isFunctionGroup ? (
                                <div
                                    onClick={() => handleToggleExpand(key)}
                                    className={`mensagem-function ${expandedGptRunIds[key] ? 'expanded' : ''}`}
                                >
                                    {expandedGptRunIds[key] ? (
                                        conversasGroup.map((conversa) => (
                                            <div key={conversa.id} className="card-conversa-inner">
                                                <div className={`mensagem-entrada ${conversa.origem === 'function' ? 'mensagem-function-entrada' : ''}`}>
                                                    {conversa.origem === 'function' && isJson(conversa.mensagemEntrada) ? formatJsonMessage(conversa.mensagemEntrada) : <span>{conversa.mensagemEntrada}</span>}
                                                </div>
                                                <div className={`mensagem-saida ${conversa.origem === 'function' ? 'mensagem-function-saida' : ''}`}>
                                                    {conversa.origem === 'function' && isJson(conversa.mensagemSaida) ? formatJsonMessage(conversa.mensagemSaida) : <span>{conversa.mensagemSaida}</span>}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="mensagem-function-title">
                                            <span>functions</span>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="mensagem-entrada">
                                    <div>{conversasGroup[0].mensagemEntrada}</div>
                                </div>
                            )}
                            {!isFunctionGroup && (
                                <div className="mensagem-saida">
                                    {conversasGroup[0].mensagemSaida}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            {loading && <div className="loading-message">Carregando...</div>}
            {currentPage >= totalPages && !loading && conversas.length > 0 && <div className="end-message">Não existem mais registros</div>}
        </div>
    );
};

export default ConversasList;
