import React, { useEffect, useState } from 'react';
import './FormContainer.css'; // Importar o arquivo CSS para estilos adicionais

const FormContainer = ({ children }) => {
    const [imageSrc, setImageSrc] = useState('');

    useEffect(() => {
        const getRandomImage = () => {
            const randomNumber = Math.floor(Math.random() * 22) + 1;
            return `https://topgpt.azureedge.net/site/bemvindo_${randomNumber.toString().padStart(2, '0')}.jpg`;
        };

        setImageSrc(getRandomImage());
    }, []);

    return (
        <div className="form-container">
            <div className="image-container">
                {imageSrc && <img src={imageSrc} alt="Welcome Robot" className="wellcome-image" />}
            </div>
            <div className="form-content">
                <div className="form-content-child">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default FormContainer;
