import React, { useState } from 'react';
import FormButtonRow from '../../objects/FormButtonRow';
import { useModal } from '../../objects/ModalProvider';
import { useAuth } from '../../auth/AuthContext';

const SenhaForm = ({ onCancel, submitLabel }) => {
    const { userId, token } = useAuth();
    const [senhaData, setSenhaData] = useState({
        Id: userId,
        SenhaAtual: '',
        SenhaNova: '',
        ConfirmaNovaSenha: '',
    });
    const { showModal } = useModal();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (senhaData.SenhaNova !== senhaData.ConfirmaNovaSenha) {
            showModal('Erro', 'Senhas não coincidem.', 'error');
            return;
        }
        try {
            const response = await fetch('usuario/alterarsenha', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, 
                },
                body: JSON.stringify({ ...senhaData, userId })
            });
            if (response.ok) {
                showModal('Senha atualizada', 'Senha atualizada com sucesso !', 'success', null, 2000);
                onCancel();
            } else {
                const text = await response.text();
                const data = JSON.parse(text);
                const errorMessages = Object.entries(data.errors || {})
                    .map(([key, value]) => `${key}: ${value.join(', ')}`)
                    .join('\n');
                showModal('Erro ao atualizar senha:', errorMessages, 'error');
            }
        } catch (error) {
            console.error("Failed to update password:", error);
            showModal('Erro técnico ao atualizar senha.', 'Tente novamente.', 'error');
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSenhaData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <form onSubmit={handleSubmit} className="senha-form">
            <div className="form-group">
                <label>Senha Atual:</label>
                <input
                    type="password"
                    name="SenhaAtual"
                    value={senhaData.SenhaAtual}
                    onChange={handleChange}
                    className="form-control"
                    required
                />
            </div>
            <div className="form-group">
                <label>Nova Senha:</label>
                <input
                    type="password"
                    name="SenhaNova"
                    value={senhaData.SenhaNova}
                    onChange={handleChange}
                    className="form-control"
                    required
                />
            </div>
            <div className="form-group">
                <label>Confirme a Nova Senha:</label>
                <input
                    type="password"
                    name="ConfirmaNovaSenha"
                    value={senhaData.ConfirmaNovaSenha}
                    onChange={handleChange}
                    className="form-control"
                    required
                />
            </div>
            <FormButtonRow onSubmit={handleSubmit} onCancel={onCancel} submitLabel={submitLabel} />
        </form>
    );
};

export default SenhaForm;