import React, { useState, useEffect } from 'react';
import './EnderecoList.css';
import { useModal } from '../objects/ModalProvider';
import { useAuth } from '../auth/AuthContext';

const EnderecoList = ({ idCliente, onEdit, onNew }) => {
    const [enderecos, setEnderecos] = useState([]);
    const [error, setError] = useState(null);
    const { showModal } = useModal();
    const { token } = useAuth();

    useEffect(() => {
        const fetchEnderecos = async () => {
            try {
                console.log(`Fetching endereços for idCliente: ${idCliente}`);
                const response = await fetch(`/endereco?idClienteExterno=${idCliente}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                const text = await response.text();
                try {
                    const data = JSON.parse(text);
                    setEnderecos(Array.isArray(data) ? data : []);
                } catch (error) {
                    console.error('Failed to parse JSON:', text);
                    throw error;
                }
            } catch (err) {
                console.error('Failed to fetch endereços:', err);
                setError(err.message);
            }
        };

        if (idCliente) {
            fetchEnderecos();
        } else {
            console.log("idCliente is undefined");
        }
    }, [idCliente, token]);

    const confirmaDelete = (id) => {
        showModal('Confirmar exclusão', 'Você tem certeza que deseja excluir este endereço?', 'question', null, null, () => handleDelete(id));
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch(`/endereco?id=${id}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${token}`, }
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erro ao deletar endereço');
            }
            setEnderecos(enderecos.filter(endereco => endereco.id !== id));
        } catch (err) {
            console.error('Failed to delete endereço:', err);
            setError(err.message);
        }
    };

    return (
        <div>
            <div className="endereco-header">
                <button onClick={() => onNew()} className="btn btn-primary">Novo</button>
            </div>
            {error && <p className="error">{error}</p>}
            <div className="card-endereco-container">
                {enderecos.map(endereco => (
                    <div className="card-endereco" key={endereco.id} onClick={() => onEdit(endereco)}>
                        <div className="card-endereco-header">
                            <div className="title">{endereco.cidade} - {endereco.bairro}</div>
                            <div className="card-endereco-actions" onClick={(e) => e.stopPropagation()}>
                                <button className="btn-delete" onClick={() => confirmaDelete(endereco.id)}>
                                    <i className="fas fa-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                        <div className="card-endereco-content">
                            <p>{endereco.rua}, {endereco.numero}</p>
                            <p>{endereco.complemento}</p>
                            <p>{endereco.tipoEndereco}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EnderecoList;
