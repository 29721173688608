import React, { useState } from 'react';
import CadastroForm from './CadastroForm';
import SenhaForm from './SenhaForm';
import AssistenteForm from './AssistenteForm';
import ContextoForm from './ContextoForm';
import './Cadastro.css'; 
import { FaUserEdit, FaLock, FaRegHandshake, FaInfoCircle } from 'react-icons/fa';
//import { FaUserEdit, FaLock, FaRegHandshake, FaMoneyCheckAlt, FaInfoCircle } from 'react-icons/fa';
import { useAuth } from '../../auth/AuthContext';

const Menu = ({ activeComponent, setActiveComponent, username }) => {
    const menuItems = [
        { id: 'cadastro', icon: <FaUserEdit />, title: 'Cadastro', description: 'ver e alterar seus dados, seu e-mail' },
        { id: 'senha', icon: <FaLock />, title: 'Senha', description: 'alterar sua senha' },
        { id: 'assistentes', icon: <FaRegHandshake />, title: 'Assistentes', description: 'contratar ou cancelar assinaturas' },
        { id: 'contexto', icon: <FaInfoCircle />, title: 'Contexto GPT', description: 'fornecer contexto extra para atendimento' },
        //        { id: 'pagamentos', icon: <FaMoneyCheckAlt />, title: 'Pagamentos', description: 'gerenciar seus meios de pagamento' },
    ];

    const firstName = username.split(' ')[0]; // Extrai o primeiro nome do usuário
    const firstLetter = username[0].toUpperCase(); // Extrai a primeira letra do nome do usuário

    return (
        <div className="cadastro-menu">
            <div className="cadastro-header">
                <div className="cadastro-avatar">{firstLetter}</div>
                <div className="cadastro-welcome">olá {firstName}, suas informações</div>
            </div>
            {menuItems.map(item => (
                <div
                    key={item.id}
                    className={`cadastro-menu-item ${activeComponent === item.id ? 'active' : ''}`}
                    onClick={() => setActiveComponent(item.id)}
                >
                    {item.icon}
                    <div className="cadastro-menu-text">
                        <div className="cadastro-menu-title">{item.title}</div>
                        <div className="cadastro-menu-description">{item.description}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const Cadastro = () => {
    const { username } = useAuth(); // Recupera o nome do usuário
    const [activeComponent, setActiveComponent] = useState('cadastro');

    const renderComponent = () => {
        switch (activeComponent) {
            case 'cadastro':
                return <CadastroForm onSave={() => console.log('Saved')} onCancel={() => setActiveComponent('cadastro')} submitLabel="Salvar" />;
            case 'senha':
                return <SenhaForm onSave={() => console.log('Senha Salva')} onCancel={() => console.log('Cancelled')} submitLabel="Salvar Senha" />;
            case 'assistentes':
                return <AssistenteForm onSave={() => console.log('Senha Salva')} onCancel={() => console.log('Cancelled')} submitLabel="Salvar Senha" />;
            //case 'pagamentos':
            //    return <div>Pagamentos Component</div>;
            case 'contexto':
                return <ContextoForm onSave={() => console.log('Saved')} onCancel={() => console.log('Cancelled')} submitLabel="Salvar" />;
            default:
                return <div>Component not found</div>;
        }
    };

    return (
        <div className="cadastro-container">
            <Menu activeComponent={activeComponent} setActiveComponent={setActiveComponent} username={username} />
            <div className="cadastro-content">
                {renderComponent()}
            </div>
        </div>
    );
};

export default Cadastro;
