import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import FormButtonRow from '../objects/FormButtonRow';
import InputMask from 'react-input-mask';
import Flatpickr from "react-flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import './UsuarioForm.css';
import { useModal } from '../objects/ModalProvider';

const UsuarioForm = ({ onCancel, submitLabel }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, token} = useAuth();
    const [mask, setMask] = useState("(99) 99999-9999");
    const [usuario, setUsuario] = useState({
        idUsuario: userId,
        nome: '',
        telefone: '',
        email: '',
        cpf: '',
        nascimento: new Date(),
        responsavel: '',
        telefoneResponsavel: '',
        indicacao: '',
    });
    const { showModal } = useModal();

    useEffect(() => {
        if (id) {
            const fetchUsuario = async () => {
                try {
                    const response = await fetch(`./usuario/unico?idExterno=${id}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } });
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setUsuario({
                        ...data,
                        nascimento: data.nascimento ? new Date(data.nascimento) : '',
                        telefone: data.telefone.startsWith('55') ? data.telefone.substring(2) : data.telefone
                    });
                    defineMascara(data.telefone);
                } catch (error) {
                    console.error('There was a problem with the fetch operation:', error);
                }
            };

            fetchUsuario();
        }
    }, [id, token]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setUsuario(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'telefone' || name === 'telefoneResponsavel') {
            defineMascara(value);
        }

    };

    const defineMascara = (valor) => {
        let numericValue = valor.replace(/\D/g, '');

        if (numericValue.startsWith('55')) {
            numericValue = numericValue.substring(2);
        }

        if (numericValue[2] >= '6') {
            setMask("(99) 99999-9999");
        } else {
            setMask("(99) 9999-9999");
        }
    }

    const handleDateChange = (date) => {
        setUsuario(prevState => ({
            ...prevState,
            nascimento: date[0]
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const method = id ? 'PUT' : 'POST';
        const url = id ? `./usuario?idUsuario=${id}` : './usuario/novousuario';

        try {

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, 
                },
                body: JSON.stringify(usuario)
            });
            if (response.ok) {
                showModal('Sucesso', 'Usuário salvo com sucesso!', 'success', '/controleusuario', 2000);
            } else {
                const data = await response.json();
                const errorMessages = Object.entries(data.errors || {})
                    .map(([key, value]) => `${key}: ${value.join(', ')}`)
                    .join('\n');
                showModal('Erro ao salvar usuário:', errorMessages, 'error');
            }
        } catch (error) {
            showModal('Erro na requisição:', 'Erro técnico ao salvar usuário. ' + error.message, 'error');
        }
    };

    const handleCancel = () => {
        onCancel();
        navigate('/controleusuario', { state: { message: "Operação cancelada.", searchQuery: location.state?.searchQuery || '' } });
    };

    return (
        <form onSubmit={handleSubmit} className="usuario-form">
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label>Nome:</label>
                    <input
                        type="text"
                        name="nome"
                        value={usuario.nome}
                        onChange={handleChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Telefone:</label>
                    <InputMask
                        mask={mask}
                        maskChar={null}
                        value={usuario.telefone}
                        onChange={handleChange}
                        type="tel"
                        name="telefone"
                        className="form-control"
                        required
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={usuario.email}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>CPF:</label>
                    <InputMask
                        mask="999.999.999-99"
                        maskChar={null}
                        type="text"
                        name="cpf"
                        value={usuario.cpf}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label>Data de Nascimento:</label>
                    <Flatpickr
                        value={usuario.nascimento}
                        onChange={handleDateChange}
                        options={{
                            dateFormat: "d/m/Y",
                            locale: Portuguese,
                            altInput: true,
                            altFormat: "d/m/Y",
                            enableTime: false,
                            noCalendar: false,
                            time_24hr: true
                        }}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Indicação:</label>
                    <input
                        type="text"
                        name="indicacao"
                        value={usuario.indicacao}
                        onChange={handleChange}
                        className="form-control"
                    />
                </div>
            </div>


            <FormButtonRow onSubmit={handleSubmit} onCancel={handleCancel} submitLabel={submitLabel} />
        </form>
    );
};

export default UsuarioForm;
