import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout'; 
import './custom.css';
import { ModalProvider } from './objects/ModalProvider'; // Importando o ModalProvider

export default class App extends Component {
    static displayName = App.name;

    onLogin = () => {
        console.log('Login bem-sucedido!');
    };

    render() {
        return (
            <ModalProvider> {/* Envolvendo o aplicativo com o ModalProvider */}
                <Layout>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const element = route.path === '/Login' ?
                                React.cloneElement(route.element, { onLogin: this.onLogin }) :
                                route.element;

                            return <Route key={index} {...route} element={element} />;
                        })}
                    </Routes>
                </Layout>
            </ModalProvider>
        );
    }
}
