import React, { createContext, useState, useContext } from 'react';
import AlertModal from './AlertModal';

const ModalContext = createContext();

export const useModal = () => {
    return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
    const [modalState, setModalState] = useState({
        show: false,
        title: '',
        message: '',
        type: '',
        redirectLink: null,
        timeout: null,
        onConfirm: null
    });

    const showModal = (title, message, type, redirectLink = null, timeout = null, onConfirm = null) => {
        setModalState({
            show: true,
            title,
            message,
            type,
            redirectLink,
            timeout,
            onConfirm
        });

        if (!redirectLink && timeout && timeout > 0) {
            setTimeout(() => {
                closeModal();
            }, timeout);
        }
    };

    const closeModal = () => {
        setModalState(prevState => ({
            ...prevState,
            show: false
        }));
    };

    return (
        <ModalContext.Provider value={{ showModal, closeModal }}>
            {children}
            <AlertModal
                show={modalState.show}
                onClose={closeModal}
                title={modalState.title}
                message={modalState.message}
                type={modalState.type}
                redirectLink={modalState.redirectLink}
                timeout={modalState.timeout}
                onConfirm={modalState.onConfirm}
            />
        </ModalContext.Provider>
    );
};
