import React, { useState, useEffect } from 'react';
import './SearchComponent.css';
import { useAuth } from '../auth/AuthContext';

const SearchComponent = ({ searchUrl, placeholder, mapResults, onUserSelect, value }) => {
    const [search, setSearch] = useState('');
    const [results, setResults] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [ignoreNextSearch, setIgnoreNextSearch] = useState(false);
    const { token } = useAuth();

    useEffect(() => {
        if (value) {
            setSearch(value);
            setIgnoreNextSearch(true);
        }
    }, [value]);

    useEffect(() => {
        if (search.length >= 3 && !ignoreNextSearch) {
            fetch(`${searchUrl}&consulta=${search}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, } })
                .then(response => response.json())
                .then(data => {
                    setResults(data);
                    setSelectedIndex(-1);
                })
                .catch(error => console.error('Error fetching data:', error));
        } else {
            setResults([]);  
        }
    }, [search, searchUrl, ignoreNextSearch, token]);

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowDown' && selectedIndex < results.length - 1) {
            setSelectedIndex(selectedIndex + 1);
        } else if (event.key === 'ArrowUp' && selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
        } else if (event.key === 'Enter' && selectedIndex > -1) {
            const selectedItem = results[selectedIndex];
            setSearch(mapResults(selectedItem).line1);
            onUserSelect(selectedItem);
            setResults([]);
            setIgnoreNextSearch(true);
        }
    };

    return (
        <div className="search-container">
            <input
                type="text"
                className="search-input"
                placeholder={placeholder}
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value);
                    setIgnoreNextSearch(false);
                }}
                onKeyDown={handleKeyDown}
            />
            {results.length > 0 && !ignoreNextSearch && (  
                <ul className="search-results">
                    {results.map((item, index) => (
                        <li key={item.id}
                            className={`search-result-item ${index === selectedIndex ? 'selected' : ''}`}
                            onClick={() => {
                                const result = mapResults(item);
                                setSearch(result.line1);
                                onUserSelect(item);
                                setResults([]);
                                setIgnoreNextSearch(true);
                            }}>
                            <div>{mapResults(item).line1}</div>
                            <div className="user-phone">{mapResults(item).line2}</div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchComponent;
