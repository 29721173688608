import React from 'react';
import { useAuth } from '../auth/AuthContext';
import HomeUser from './HomeUser';
import HomeGeral from './HomeGeral';

const Home = () => {
    const { userId } = useAuth();

    return (
        <div className="page-container">
            {userId ? <HomeUser /> : <HomeGeral />}
        </div>
    );
};

export default Home;
