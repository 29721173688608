import React from 'react';
import SliderTG from '../objects/SliderTG';
import HomeIntelligence from '../objects/HomeIntelligence';
import HomeProduct from '../objects/HomeProduct';
import HomeTrust from '../objects/HomeTrust';
import HomeRecommend from '../objects/HomeRecommend';
import HomeFooter from '../objects/HomeFooter';

const HomeGeral = () => {

    return (
        <div className="page-container">
            <SliderTG />
            <HomeIntelligence />
            <HomeProduct />
            <HomeTrust />
            <HomeRecommend />
            <HomeFooter />
        </div>
    );
};

export default HomeGeral;
